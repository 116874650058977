import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { headerTitles } from "../../actions/masterdata/masterdata";
import { get_all_payments } from "../../actions/z_accounts";
import { currencyMapper, payment_status } from "../../config/helper";
import BankShow from "../Common/BankShow";
import BaseCurrency from "../Common/BaseCurrency";
import Table from "../Common/Table/Table";
import { p_types } from "../Payments/SendMoney/Recipients";
import SendMoney from "../Payments/SendMoney/SendMoney";
import credit from "../../assets/icons/credit.png";
import debit from "../../assets/icons/debit.png";
// import Base from "antd/lib/typography/Base";

const AccountTransaction = ({ sendMoney }) => {
  const dispatch = useDispatch();
  const [openAddNew, setOpenAddNew] = useState(false);
  const [page, setPage] = useState(1);
  const masterHistoryResponse = useSelector(
    (state) => state?.z_accs?.all_payments?.data?.data ?? []
  );
  const totalRecords = useSelector(
    (state) => state?.z_accs?.all_payments?.data?.total_count ?? []
  );

  React.useEffect(() => {
    // dispatch(accountTxns(({ page_number: 1 })));
    dispatch(
      headerTitles(
        sendMoney
          ? {
              title: "Send Money",
              description: "Payments Transfer to Recipients Accounts",
            }
          : {
              title: "account_transactions",
              description: "View all your global account transactions",
            }
      )
    );
    if (!sendMoney) {
      setOpenAddNew(false);
    }
    if (!openAddNew) dispatch(get_all_payments({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendMoney, openAddNew]);
  const { t } = useTranslation();

  const columns = [
    {
      title: t("txn_id"),
      dataIndex: "transaction_ref_no",
      key: "transaction_ref_no",
    },
    {
      title: t("beneficiary_name"),
      dataIndex: "beneficiary_details",
      key: "beneficiary_details",
      render: (text, rec) =>
        text?.beneficiary_name
          ? text?.beneficiary_name
          : rec?.counterparty_account_name,
    },
    {
      title: t("beneficiary_account"),
      dataIndex: "counterparty_account_number",
      key: "counterparty_account_number",
    },
    {
      title: t("Date and Time"),
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => <div>{moment(text).format("DD, MMM YY, HH:mm")}</div>,
    },
    {
      title: "Payment Method",
      dataIndex: "transfer_mode",
      key: "transfer_mode",
      render: (text) => p_types?.find((ele) => ele.value === text)?.label,
    },

    {
      title: t("Amount"),
      dataIndex: "amount",
      key: "amount",
      render: (text, rec) => (
        <div>
          {currencyMapper[rec.currency]}
          {text}
        </div>
      ),
    },
    {
      title: "Txn Type",
      dataIndex: "transaction_type",
      key: "transaction_type",
      render: (text) => (
        <span
          style={{
            color: "#333",
            textTransform: "capitalize",
          }}
        >
          <img src={text === "credit" ? credit : debit} width={10} /> {text}
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => (
        <span className={`status status_${text?.toLowerCase()}`}>{text}</span>
      ),
    },
  ];

  return openAddNew ? (
    <>
      <SendMoney
        setOpenAddNew={setOpenAddNew}
        cb={() => {
          dispatch(get_all_payments({}));
        }}
      />
    </>
  ) : (
    <>
      <BankShow
        noBack={true}
        noDownload={true}
        addBtn={sendMoney}
        setOpenAddNew={setOpenAddNew}
        text={"Send Money"}
        callAction={(acc, s_date, e_date) => {
          dispatch(
            get_all_payments({
              params: { account_id: acc },
              filters: {
                created_at:
                  s_date && e_date
                    ? {
                        start_date: s_date,
                        end_date: e_date,
                      }
                    : undefined,
              },
            })
          );
        }}
      />
      <br />
      <Table
        rowKey="id"
        columns={columns}
        data={Array.isArray(masterHistoryResponse) ? masterHistoryResponse : []}
        isTopFilter={false}
        className="tableStyled"
        topFilters={[
          { label: t("all_trans"), action: () => {} },
          { label: t("advance_search"), action: () => {} },
        ]}
        scroll={{
          x: 1250,
        }}
        pagination={{
          total: totalRecords || 0,
          current: page,
          onChange: (pagee) => {
            dispatch(
              get_all_payments({
                params: {
                  page_number: pagee,
                },
              })
            );

            setPage(pagee);
          },
        }}
      />
    </>
  );
};

export default AccountTransaction;
