import { yupResolver } from "@hookform/resolvers/yup";
import { Input, Typography, message } from "antd";
import jwt_decode from "jwt-decode";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import * as yup from "yup";
import { apis } from "../../config/APIs";
import endpointGridApi from "../../config/AxiosGridApi";
import endpointPayApi from "../../config/AxiosPayApi";
import endpointSettingsApi from "../../config/AxiosSettingsApi";
import { gridApis } from "../../config/GridApis";
import useNotificationAxios from "../../config/useNotification";
import { RouteConstants } from "../../utils";
import OTPComponent from "../Common/OTPComponent";
const { Text, Title } = Typography;

message.config({
  maxCount: 3,
});

const ValidationSchema = yup.object().shape({
  otp: yup.string().min(6).max(6).required("Please provide valid email."),
});

const TwoStepVerify = () => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    trigger,
  } = useForm({
    defaultValues: {
      otp: "",
    },
    resolver: yupResolver(ValidationSchema),
  });
  const [otp, setOtp] = useState("");
  const loginResponse = useSelector((state) => state.auth.login || {});
  const history = useHistory();

  const onSubmit = (values) => {
    const payload = {};

    ["otp"].forEach((item) => {
      payload[item] = values[item];
    });

    ValidationSchema.validate(payload)
      .then(function ({ ...value }) {
        const token = jwt_decode(localStorage.getItem("token"));
        // onCall({
        //   data: {
        //     "user_id": token.user_id,
        //     "email_id": localStorage.getItem("user"),
        //     "source": "LOGIN",
        //     "otp": value.otp
        //   }
        // }).then(res => {
        //   if (res.error === false) {
        try {
          // endpointPayApi
          //   .get(
          //     `v1/ems/0/check/generateToken?user_id=${token.user_id}&company_id=${token.company_id}&session_id=${token.session_key}`
          //   )
          //   .then((responseToken) => {
          //     if (responseToken?.data?.status === 200) {
          //       const payApiToken = responseToken?.data?.data;
          //       const payApiTokenDecoded = jwt_decode(payApiToken);
                // endpointGridApi
                //   .post(gridApis.authentication, {
                //     client_user_id: "f2d0ce5e-5efa-44d4-b345-5b15082196a6",
                //     client_id: "336833d5-df0e-403a-bff4-1bc421de2376",
                //   })
                //   .then((res) => {
                //     localStorage.setItem("tokenGridApi", res?.data?.data);
                    history.push(RouteConstants.DASHBOARD);
                //   });
            //     localStorage.setItem("tokenPayApi", payApiToken);
            //     localStorage.setItem(
            //       "user_id_payApi",
            //       payApiTokenDecoded.user_id
            //     );
            //     endpointSettingsApi
            //       .get(apis.getCompanyBaseCurrency, {
            //         params: { base_currency: true },
            //       })
            //       .then((res) => {
            //         localStorage.setItem(
            //           "base_currency",
            //           JSON.stringify(res?.data?.data?.base_currency)
            //         );
            //         history.push(RouteConstants.DASHBOARD);
            //       })
            //       .catch((e) => {
            //         console.log(e?.response?.data?.message);
            //       });
            //   }
            // });
        } catch (error) {
          console.error(error);
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  return (
    <div className="login-form">
      <div className="head-sec">
        <Title level={3}>{t("two_step")}</Title>
        <Text>{t("enter_otp")}</Text>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <OTPComponent
          value={otp}
          onChange={(value) => {
            setOtp(value);
            setValue("otp", value);
            trigger("otp");
          }}
        />
        <Input
          {...register("otp")}
          placeholder={t("enter_otp")}
          type="hidden"
        />
        {errors.otp?.message && (
          <div className="errorMsg">{errors.otp.message}</div>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button type="submit" className="loginButton primaryButton mt2">
            {loginResponse?.loading ? (
              <ClipLoader
                loading={loginResponse?.loading}
                size={20}
                color="#fff"
              />
            ) : (
              t("verify_otp")
            )}
          </button>
        </div>
      </form>
      {/* <div className="mt4" style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}>
        <span className="text-dim footer-note">
          {t("have_an_account")}
          <b className="cursor" onClick={() => {
            history.push("/")
          }}>{t("login")}</b>
        </span>
      </div> */}
    </div>
  );
};

export default TwoStepVerify;
