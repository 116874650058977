import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Input,
  Select,
  Switch,
  DatePicker,
  Radio,
  Row,
  Col,
  message,
} from "antd";
import moment from "moment";
import { FormInput, FormSelect } from "../../inputs";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  amountFormat,
  calculatePercentageAmt,
  convertCurrency,
  reverseCurrencyConverter,
} from "../../../utils";
import endpointSettingsApi from "../../../config/AxiosSettingsApi";
import { apis } from "../../../config/APIs";
import { currencyMapper, exchageRates } from "../../../config/helper";
import DateInput from "../../inputs/DateInput";

const { Option } = Select;

const SchedulePaymentForm = ({
  next,
  back,
  onSubmit: onSub,
  setDBAcc,
  acc,
  crAcc,
  mainData,
}) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    watch,
    register,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      amount: "",
      send_on: "Today",
    },
  });
  const [exchange, setExchange] = useState({});
  const watchRepeatPayment = watch("repeatPayment", false);
  const s_amt = watch("sender_amount");
  const amt = watch("amount");
  const data1 = useSelector(
    (state) => state?.z_accs?.accounts?.data?.data || []
  );
  const [customFrequency, setCustomFrequency] = useState(false);
  const onSubmit = (data) => {
    onSub(data);
    next();
  };

  useEffect(() => {
    if (mainData?.method?.includes("SWIFT")) {
      // endpointSettingsApi
      //   .get(apis.getCompanyBaseCurrency, {
      //     params: { company_currency_id: mainData?.currency },
      //   })
      //   .then((res) => {
      //     if (
      //       !res?.data?.data?.live_exchange_rates?.rate &&
      //       !res?.data?.data?.manual_exchange_rates?.rate
      //     ) {
      //       message.error(
      //         <span className="messageText">{res?.data?.message}</span>
      //       );
      //     }
      setExchange({
        auto_currency: true,
        base_currency: mainData?.iso_code,
        convert_currency: "USD",
        live_exchange_rates: { rate: exchageRates[mainData?.iso_code] },
      });
      // })
      // .catch((e) => {
      //   message.error(
      //     <span className="messageText">{e?.response?.data?.message}</span>
      //   );
      // });
    }
  }, [mainData?.method]);

  useEffect(() => {
    if (s_amt) {
      setValue(
        "amount",
        mainData?.method?.includes("SWIFT")
          ? Number(s_amt) + Number(calculatePercentageAmt(s_amt, 1))
          : s_amt
      );
      if (mainData?.method?.includes("SWIFT")) {
        setValue("exchange_fee", calculatePercentageAmt(s_amt, 1));
        setValue(
          "exchange_rate",
          exchange?.live_exchange_rates?.rate ??
            exchange?.manual_exchange_rates?.rate ??
            ""
        );
      }
    }
  }, [s_amt]);

  const send_on = watch("send_on");

  return (
    <form className="w40 ml3" onSubmit={handleSubmit(onSubmit)}>
      <div className="pp-form-item">
        <FormInput
          prefix={
            currencyMapper[mainData?.iso_code ?? crAcc?.currency_code] ?? "$"
          }
          control={control}
          required
          {...register("recipient_amount", {
            pattern: {
              value: /^[0-9.]+$/i,
              message: `${t("amount_validation")}`,
            },
            required: `${t("amount_field") + " " + t("is_Requrired")}`,
            onChange: (e) => {
              if (mainData?.method?.includes("SWIFT"))
                setValue(
                  "sender_amount",
                  reverseCurrencyConverter(e.target.value, exchange)
                );
              else setValue("amount", e.target.value);
            },
          })}
          errors={errors}
          label={"Receipent Gets"}
          number={true}
          suffix={
            <>
              <span className="value">
                <img
                  src={`https://cdn.jsdelivr.net/npm/svg-country-flags@1.2.10/svg/${mainData?.iso_code
                    ?.substr(0, 2)
                    ?.toLowerCase()}.svg`}
                  className="mr1"
                  width={20}
                  style={{
                    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.4)",
                  }}
                />
                {mainData?.iso_code}
              </span>
            </>
          }
        />
      </div>
      {mainData?.method?.includes("SWIFT") && (
        <div className="pp-form-item">
          <FormInput
            prefix={"$"}
            control={control}
            required
            {...register("sender_amount", {
              pattern: {
                value: /^[0-9.]+$/i,
                message: `${t("amount_validation")}`,
              },
              required: `${t("amount_field") + " " + t("is_Requrired")}`,
              onChange: (e) => {
                setValue(
                  "recipient_amount",
                  convertCurrency(e.target.value, exchange)
                );
              },
            })}
            errors={errors}
            label={"You Send"}
            suffix={
              <>
                <span className="value">
                  <img
                    src={`https://cdn.jsdelivr.net/npm/svg-country-flags@1.2.10/svg/us.svg`}
                    className="mr1"
                    width={20}
                    style={{
                      boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.4)",
                    }}
                  />{" "}
                  USD
                </span>
              </>
            }
          />
          <div className="memo mt2">
            <div className="flex-between mtHalf">
              <span className="label">Rate</span>
              <span className="value">
                1 {exchange?.convert_currency} ={" "}
                {exchange?.live_exchange_rates?.rate ??
                  exchange?.manual_exchange_rates?.rate}{" "}
                {exchange?.base_currency}
              </span>
            </div>
            <div className="flex-between mtHalf">
              <span className="label">Exchange Fee (1%)</span>
              <span className="value">
                $ {amountFormat(calculatePercentageAmt(s_amt, 1))}
              </span>
            </div>
            <div className="flex-between mtHalf">
              <span className="label">Total</span>
              <span className="value">$ {amountFormat(amt ? amt : 0)}</span>
            </div>
          </div>
        </div>
      )}

      <div className="pp-form-item">
        <FormSelect
          inline
          label={"Send From"}
          control={control}
          options={data1?.map((ele) => ({
            label: `${ele?.account_name} - ${ele?.account_number}`,
            value: ele?.id,
          }))}
          required={true}
          {...register("send_from", {
            required: "Please select Send From",
            onChange: (e) => {
              setDBAcc(data1?.find((ele) => ele.id === e.target.value));
            },
          })}
          errors={errors}
          hideCreateOption={true}
        />
        {acc?.id && (
          <p className="mt1">
            Available Balance: ${" "}
            {amountFormat(acc?.balance?.balance)}
          </p>
        )}
      </div>

      <div style={{ marginTop: 20 }}>
        <FormSelect
          inline
          label={"Send On"}
          control={control}
          options={[
            { label: "Today", value: "Today" },
            { label: "Tomorrow", value: "Tomorrow" },
            { label: "Custom", value: "Custom" },
          ]}
          {...register("send_on")}
          errors={errors}
          hideCreateOption={true}
        />
      </div>

      {send_on === "Custom" && (
        <div style={{ marginTop: 20 }}>
          <DateInput
            control={control}
            {...register("payment_date")}
            errors={errors}
            label={t("payment_date")}
            dateFormat={["DD/MM/YYYY"]}
          />
        </div>
      )}

      <div style={{ marginTop: 20 }}>
        <Controller
          name="repeatPayment"
          control={control}
          defaultValue={false}
          render={({ field }) => <Switch {...field} />}
        />
        <label className="ml1">Repeat this payment</label>
      </div>

      {watchRepeatPayment && (
        <>
          <div className="pp-form-item" style={{ marginTop: 20 }}>
            <FormSelect
              inline
              label={"Repeat"}
              control={control}
              options={[
                { label: "Weekly on Monday", value: "Weekly on Monday" },
                { label: "Monthly on the 1st", value: "Monthly on the 1st" },
                { label: "Custom", value: "Custom" },
              ]}
              {...register("repeatFrequency", {
                onChange: (e) => {
                  setCustomFrequency(e.target.value === "Custom");
                },
              })}
              errors={errors}
              hideCreateOption={true}
            />
          </div>

          {customFrequency && (
            <div style={{ marginTop: 20 }}>
              <Row gutter={24}>
                <Col span={12}>
                  <div className="pp-form-item">
                    <FormInput
                      label={"Repeat Every"}
                      control={control}
                      {...register("repeatEveryNumber")}
                      errors={errors}
                    />
                  </div>
                </Col>
                <Col span={12}>
                  <div className="pp-form-item">
                    <FormSelect
                      inline
                      label={"Repeat"}
                      control={control}
                      options={[
                        { label: "Day", value: "day" },
                        { label: "Week", value: "week" },
                        { label: "Month", value: "month" },
                      ]}
                      {...register("repeatEveryUnit")}
                      errors={errors}
                      hideCreateOption={true}
                      noPaddingTop
                    />
                  </div>
                </Col>
              </Row>

              <div style={{ marginTop: 20 }}>
                <Radio.Group>
                  <Radio value="day">
                    Day
                    <Controller
                      name="repeatDay"
                      control={control}
                      defaultValue={1}
                      render={({ field }) => (
                        <Input
                          {...field}
                          style={{ width: 60, marginLeft: 10 }}
                        />
                      )}
                    />
                  </Radio>
                  <Radio value="the">
                    The
                    <Controller
                      name="repeatTheNumber"
                      control={control}
                      defaultValue={1}
                      render={({ field }) => (
                        <Select
                          {...field}
                          defaultValue={1}
                          style={{ width: 60, marginLeft: 10 }}
                        >
                          <Option value={1}>1</Option>
                          <Option value={2}>2</Option>
                          <Option value={3}>3</Option>
                          <Option value={4}>4</Option>
                        </Select>
                      )}
                    />
                    <Controller
                      name="repeatTheDay"
                      control={control}
                      defaultValue="Monday"
                      render={({ field }) => (
                        <Select
                          {...field}
                          defaultValue="Monday"
                          style={{ marginLeft: 10 }}
                        >
                          <Option value="Monday">Monday</Option>
                          <Option value="Tuesday">Tuesday</Option>
                          <Option value="Wednesday">Wednesday</Option>
                          <Option value="Thursday">Thursday</Option>
                          <Option value="Friday">Friday</Option>
                          <Option value="Saturday">Saturday</Option>
                          <Option value="Sunday">Sunday</Option>
                        </Select>
                      )}
                    />
                  </Radio>
                </Radio.Group>
              </div>
            </div>
          )}

          <div className="ends" style={{ marginTop: 20 }}>
            <label>Ends</label>
            <Controller
              name="ends"
              control={control}
              defaultValue="Never"
              render={({ field }) => (
                <Radio.Group {...field}>
                  <Radio value="On">
                    On
                    <Controller
                      name="endDate"
                      control={control}
                      defaultValue={moment().add(1, "year")}
                      render={({ field }) => (
                        <DatePicker
                          {...field}
                          defaultValue={moment().add(1, "year")}
                          style={{ marginLeft: 10 }}
                        />
                      )}
                    />
                  </Radio>
                  <div className="mt1">
                    <Radio value="After">After</Radio>
                    <Controller
                      name="endAfterPayments"
                      control={control}
                      defaultValue={12}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder="12"
                          style={{ width: 60, marginLeft: 10 }}
                        />
                      )}
                    />
                    <label className="ant-radio-wrapper ml1">Payments</label>
                  </div>
                  <Radio
                    value="Never"
                    style={{ display: "block", marginTop: 10 }}
                  >
                    Never
                  </Radio>
                </Radio.Group>
              )}
            />
          </div>

          <div style={{ marginTop: 20 }}>
            <p>Upcoming payments:</p>
            <p>Sep 9, Mon - $0.00</p>
            <p>Sep 16, Mon - $0.00</p>
            <p>Sep 23, Mon - $0.00</p>
            <p>No end date</p>
          </div>
        </>
      )}

      <div className="mt3">
        <button
          className="back-btn mr2"
          onClick={() => {
            back();
          }}
        >
          Back
        </button>
        <button className="send-btn" htmlType="submit">
          Next
        </button>
      </div>
    </form>
  );
};

export default SchedulePaymentForm;
