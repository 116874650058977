export const gridApis = {
  authentication: "/v1/grid/0/token/authentication",
  getAllAccounts: "/api/admin/accounts/fetchAllAccounts",
  getByID: "/api/admin/accounts/fetchAccountDetailsByID",
  masterAccountTransactions: "/api/admin/accounts/fetchAccountTransactions",
  initialisePayment: "/api/admin/payments/initialisePayment",
  confirmPayment: "/api/admin/payments/confirmPayment",
  cancelPayment: "/api/admin/payments/cancelPayment",
  getPaymentPurposes: "/api/admin/payments/getPaymentPurposes",
  getAllPayments: "/api/admin/accounts/fetchAccountTransactions",
  onBoardUser: "/api/admin/onBoard/onBoardUser",
  fetchAccountTypes: "/api/admin/accounts/fetchAccountTypes",
  createAccount: "/api/admin/accounts/createAccount",
  userDetails: "/api/admin/onBoard/userDetails",
  createPaymentLink: "/api/admin/links/createPaymentLink",
  fetchAllPaymentLinks: "/api/admin/links/fetchAllPaymentLinks",
  fetchStatementsPDF: "/api/admin/accounts/fetchStatement",
};
