import moment from "moment";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { get_all_payments } from "../../actions/z_accounts";
import { currencyMapper } from "../../config/helper";
import NoTableData from "../Common/TableComp/NoTableData";
import TableComponent from "../Common/TableComp/TableComp";
import { p_types } from "../Payments/SendMoney/Recipients";
import credit from "../../assets/icons/credit.png";
import debit from "../../assets/icons/debit.png";

const AccTxnTable = ({ account_number, dash }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const data = useSelector(
    (state) => state?.z_accs?.all_payments?.data?.data ?? []
  );

  const loading = useSelector((state) => state?.z_accs?.all_payments?.loading);
  useEffect(() => {
    if (account_number)
      dispatch(
        get_all_payments({
          params: {
            account_id: account_number,
          },
          filters: {
            created_at: {
              start_date: moment().subtract(1, "week").startOf("week"),
              end_date: moment().clone().endOf("week"),
            },
          },
        })
      );
  }, [account_number]);

  const columns = [
    {
      title: t("txn_id"),
      dataIndex: "transaction_ref_no",
      key: "transaction_ref_no",
    },
    {
      title: t("beneficiary_name"),
      dataIndex: "beneficiary_details",
      key: "beneficiary_details",
      render: (text, rec) =>
        text?.beneficiary_name
          ? text?.beneficiary_name
          : rec?.counterparty_account_name,
    },
    {
      title: t("beneficiary_account"),
      dataIndex: "counterparty_account_number",
      key: "counterparty_account_number",
    },
    {
      title: t("Date and Time"),
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => <div>{moment(text).format("DD, MMM YY, HH:mm")}</div>,
    },
    {
      title: "Payment Method",
      dataIndex: "transfer_mode",
      key: "transfer_mode",
      render: (text) => p_types?.find((ele) => ele.value === text)?.label,
    },

    {
      title: t("Amount"),
      dataIndex: "amount",
      key: "amount",
      render: (text, rec) => (
        <div>
          {currencyMapper[rec.currency]}
          {text}
        </div>
      ),
    },
    {
      title: "Txn Type",
      dataIndex: "transaction_type",
      key: "transaction_type",
      render: (text) => (
        <span
          style={{
            color: "#333",
            textTransform: "capitalize",
          }}
        >
          <img src={text === "credit" ? credit : debit} width={10} /> {text}
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => (
        <span className={`status status_${text?.toLowerCase()}`}>{text}</span>
      ),
    },
  ];

  return (
    <>
      {!loading && data?.length === 0 ? (
        <NoTableData title={"No Transactions yet."} />
      ) : (
        <div className={loading ? "shimmer" : ""}>
          <TableComponent
            loading={loading}
            rowKey="id"
            columns={columns}
            shimmerCount={5}
            hasCheckbox={false}
            data={Array.isArray(data) ? (dash ? data?.slice(0, 5) : data) : []}
            pagination={null}
            scroll={{
              x: 1450,
            }}
          />
        </div>
      )}
    </>
  );
};

export default AccTxnTable;
