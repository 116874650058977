import { Button, message } from "antd";
import React from "react";
import { apis } from "../../../../config/APIs";
import useNotificationAxios from "../../../../config/useNotification";
import OTPComponent from "../../../Common/OTPComponent";
import { useHistory } from "react-router-dom";
const OTPForm = ({
  source,
  setPageCount,
  pageCount,
  setOtp,
  otp,
  userData,
  isEmp,
}) => {
  const history = useHistory();
  const { onCall: verifyOtp, loading: verifyLoading } = useNotificationAxios({
    api: apis.payment_verify_otp,
    method: "post",
  });
  return (
    <React.Fragment>
      <div
        className="pp-form-item field_length d-flex flex-col"
        style={{ alignItems: "center" }}
      >
        {pageCount == 2 && (
          <OTPComponent
            value={otp.otp}
            loading={false}
            onChange={(otp) => {
              setOtp((o) => ({
                ...o,
                otp,
              }));
            }}
            source={source}
          />
        )}
      </div>
      <div className="otp_info">
        <span>
          Check your email!{" "}
          <span style={{ fontWeight: "800" }}>{userData?.business_email}</span>{" "}
          We've sent an OTP to your linked email.
        </span>
        <div style={{ marginTop: "0.5rem" }}>
          <span className="footer-note">
            <b
              className="cursor"
              style={{ color: "#3F81F4", textDecoration: "underline" }}
              onClick={() => {
                setPageCount(1);
              }}
            >
              Change Email ID
            </b>
          </span>
        </div>
      </div>
      <div className={`pp-form-item field_length mt2`}>
        <Button
          type="primary"
          // htmlType="submit"
          className="pp-main-button"
          id="save"
          block={true}
          onClick={() => {
            verifyOtp({
              data: {
                source,
                otp: otp.otp,
                otp_token: userData?.otp_token,
              },
            })
              .then((res) => {
                if (res.error === false) {
                  if (res.error === false) {
                    delete userData?.token;
                    if (source === "LOGIN" && !isEmp) {
                      localStorage.setItem(
                        "company_logo",
                        res?.data?.company_logo?.logo_url
                      );
                      localStorage.setItem("country", res?.data?.country);
                      localStorage.setItem("token", res?.data?.auth_token);
                      localStorage.setItem(
                        "tokenPayApi",
                        res?.data?.auth_token
                      );
                      localStorage.setItem("name", res?.data?.name);
                      localStorage.setItem("role", res?.data?.role);
                      localStorage.setItem("user", res?.data?.user);
                      localStorage.setItem("tourDone", res?.data?.tour_guide);

                      localStorage.setItem(
                        "custom_price_plan",
                        res?.data?.custom_price_plan
                      );
                      localStorage.setItem(
                        "is_kyb_completed",
                        res?.data?.is_kyb_completed
                      );
                      localStorage.setItem(
                        "is_accepted",
                        res?.data?.terms_accepted
                      );
                      localStorage.setItem(
                        "menu",
                        JSON.stringify(res?.data?.menu ? res?.data?.menu : {})
                      );
                      const timeO = setTimeout(() => {
                        if (
                          res?.data?.role?.includes("Applicant") &&
                          res?.data?.multi_org
                        ) {
                          history.push("/organisation");
                        } else {
                          history.push("/dashboard");
                        }
                        clearTimeout(timeO);
                      }, 300);
                    } else {
                      localStorage.setItem("token", res?.data?.auth_token);
                      localStorage.setItem("step", res?.data?.step + 1);
                      localStorage.setItem(
                        "tokenPayApi",
                        res?.data?.auth_token
                      );
                      const timeO = setTimeout(() => {
                        history.push("/onboard/businesskyc", {
                          country: "IN",
                        });
                        clearTimeout(timeO);
                      }, 300);
                    }
                  }
                }
              })
              .catch((err) => {
                message.error(
                  <span className="messageText">
                    {err?.response?.data?.message}
                  </span>
                );
              });
          }}
          loading={verifyLoading}
        >
          Verify OTP
        </Button>
      </div>
    </React.Fragment>
  );
};

export default OTPForm;
