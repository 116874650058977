import { takeLatest } from "redux-saga/effects";
import { gridApis } from "../../config/GridApis";
import { sagaFunctionsGrid } from "../../utils";
import {
  GetMasterAccountTxns,
  GetPayments,
  GetPersonAccounts,
  GetPurposes,
  InitializePayment,
} from "./accounts";

function getAccounts(req) {
  const params = req?.payload?.params;
  delete req?.payload?.params;
  return sagaFunctionsGrid(
    GetPersonAccounts,
    "post",
    `${gridApis.getAllAccounts}`,
    req.payload,
    {},
    params
  )();
}

function getPurposes(req) {
  return sagaFunctionsGrid(
    GetPurposes,
    "get",
    `${gridApis.getPaymentPurposes}`,
    req.payload
  )();
}

function getPayments(req) {
  const params = req?.payload?.params;
  delete req?.payload?.params;
  req.payload.sort = {
    sort_key: "updated_at",
    sort_order: "-1",
  };

  return sagaFunctionsGrid(
    GetPayments,
    "post",
    `${gridApis.getAllPayments}`,
    req.payload,
    {},
    params
  )();
}

function getAccountTxns(req) {
  const acc_id = req?.payload?.account_id;
  delete req?.payload?.account_id;
  return sagaFunctionsGrid(
    GetMasterAccountTxns,
    "get",
    `${gridApis.masterAccountTransactions}?${
      acc_id ? "account_id=" + acc_id : ""
    }`,
    req.payload
  )();
}

function initializePayment(req) {
  return sagaFunctionsGrid(
    InitializePayment,
    "post",
    `${gridApis.initialisePayment}`,
    req.payload
  )();
}

export function* z_accountsWatcher() {
  yield takeLatest(GetPersonAccounts.REQUEST, getAccounts);
  yield takeLatest(GetMasterAccountTxns.REQUEST, getAccountTxns);
  yield takeLatest(InitializePayment.REQUEST, initializePayment);
  yield takeLatest(GetPurposes.REQUEST, getPurposes);
  yield takeLatest(GetPayments.REQUEST, getPayments);
}
